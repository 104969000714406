var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "page-properties" },
    [
      _c("tev-navbar", { attrs: { title: "Properties" } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { staticClass: "mb-5", attrs: { xs12: "", sm12: "" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [
                          _vm.show
                            ? _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "mb-5",
                                      attrs: { xs12: "", sm12: "" },
                                    },
                                    [
                                      _c("div", { staticClass: "mb-3" }, [
                                        _c(
                                          "p",
                                          { staticClass: "text-muted mb-0" },
                                          [_vm._v("Welcome")]
                                        ),
                                        _vm.currentUser
                                          ? _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "headline mb-2 black--text",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.currentUser.fullname
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "align-center": "",
                                            "justify-space-between": "",
                                            row: "",
                                          },
                                        },
                                        [
                                          _c("h4", { staticClass: "ma-0" }, [
                                            _vm._v("PROPERTY LIST"),
                                          ]),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { flat: "", icon: "" },
                                              on: { click: _vm.loadProperties },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "rotate" },
                                                [_vm._v("cached")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-tabs",
                                        {
                                          attrs: { grow: "" },
                                          model: {
                                            value: _vm.tab,
                                            callback: function ($$v) {
                                              _vm.tab = $$v
                                            },
                                            expression: "tab",
                                          },
                                        },
                                        [
                                          _c("v-tabs-slider", {
                                            attrs: { color: "red" },
                                          }),
                                          _c("v-tab", [
                                            _vm._v(
                                              "\n                    Pending\n                  "
                                            ),
                                          ]),
                                          _c("v-tab", [
                                            _vm._v(
                                              "\n                    Active\n                  "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card",
                                        { attrs: { flat: "" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              class: {
                                                "pa-1":
                                                  _vm.$vuetify.breakpoint
                                                    .smAndDown,
                                              },
                                            },
                                            [
                                              _vm.loading ||
                                              (_vm.tab === 1 &&
                                                !_vm.countActive) ||
                                              (_vm.tab === 0 &&
                                                !_vm.countPending)
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-xs-center py-4",
                                                    },
                                                    [
                                                      _vm.loading
                                                        ? _c(
                                                            "v-progress-circular",
                                                            {
                                                              attrs: {
                                                                size: 50,
                                                                color:
                                                                  "primary",
                                                                indeterminate:
                                                                  "",
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-muted",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        No results found.\n                      "
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-list",
                                                    {
                                                      class: {
                                                        "pb-0":
                                                          _vm.$vuetify
                                                            .breakpoint
                                                            .smAndDown,
                                                      },
                                                      attrs: {
                                                        "two-line": "",
                                                        subheader: "",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.filteredProperties,
                                                      function (item, index) {
                                                        return _c(
                                                          "v-list-tile",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              avatar: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.clickProperty(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-tile-content",
                                                              [
                                                                _c(
                                                                  "v-list-tile-title",
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.propertyName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                item.subscriberName
                                                                  ? _c(
                                                                      "v-list-tile-sub-title",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.subscriberName
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-tile-action",
                                                              [
                                                                _c(
                                                                  "v-chip",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        _vm
                                                                          .statusColors[
                                                                          item
                                                                            .status
                                                                        ],
                                                                      "text-color":
                                                                        "white",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          item.status
                                                                        ) +
                                                                        "\n                          "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.showBlacklistWarning,
            callback: function ($$v) {
              _vm.showBlacklistWarning = $$v
            },
            expression: "showBlacklistWarning",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialogTEV" },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" },
                },
                [_c("span", [_vm._v("Warning")]), _c("v-spacer")],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  "Some of the Information entered for this property has been flagged down on the Credit Bureau's Blacklist! In accordance to FCRA regulations, our team is required to conduct further research. A Sale representative will contact you as soon as possible with additional information. Thank you! "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.showBlacklistWarning = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }