<template>
  <v-app class="page-properties">
    <tev-navbar title="Properties" />
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex xs12 sm12 class="mb-5">
          <v-container>
            <transition name="fade">
              <v-layout row wrap v-if="show">
                <v-flex xs12 sm12 class="mb-5">
                  <div class="mb-3">
                    <p class="text-muted mb-0">Welcome</p>
                    <h2 class="headline mb-2 black--text" v-if="currentUser">{{currentUser.fullname}}</h2>
                  </div>
                  <v-layout wrap align-center justify-space-between row>
                    <h4 class="ma-0">PROPERTY LIST</h4>
                    <v-btn flat icon @click="loadProperties">
                      <v-icon class="rotate">cached</v-icon>
                    </v-btn>
                  </v-layout>
                  <v-tabs v-model="tab" grow>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab>
                      Pending
                    </v-tab>
                    <v-tab>
                      Active
                    </v-tab>
                  </v-tabs>
                  <v-card flat>
                    <v-card-text :class="{'pa-1': $vuetify.breakpoint.smAndDown}">
                      <div class="text-xs-center py-4" v-if="loading||(tab===1&&!countActive)||(tab===0&&!countPending)">
                        <v-progress-circular :size="50" color="primary" indeterminate v-if="loading" />
                        <span class="text-muted" v-else>
                          No results found.
                        </span>
                      </div>
                      <v-list two-line subheader :class="{'pb-0': $vuetify.breakpoint.smAndDown}" v-else>
                        <v-list-tile v-for="(item, index) in filteredProperties" :key="index" avatar @click="clickProperty(item)">
                          <v-list-tile-content>
                            <v-list-tile-title>{{ item.propertyName }}</v-list-tile-title>
                            <v-list-tile-sub-title v-if="item.subscriberName">{{ item.subscriberName }}</v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-chip :color="statusColors[item.status]" text-color="white">
                              {{ item.status }}
                            </v-chip>
                          </v-list-tile-action>
                        </v-list-tile>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </transition>
          </v-container>
        </v-flex>
      </v-layout>
    </v-content>

    <v-dialog v-model="showBlacklistWarning" width="600">
      <v-card class="dialogTEV">
        <v-card-title class="headline lighten-2" primary-title>
          <span>Warning</span>
          <v-spacer />
          <!--v-btn icon color="white" flat @click="showBlacklistWarning = false">
            <v-icon>close</v-icon>
          </v-btn-->
        </v-card-title>
        <v-card-text>Some of the Information entered for this property has been flagged down on the Credit Bureau's Blacklist! In accordance to FCRA regulations, our team is required to conduct further research. A Sale representative will contact you as soon as possible with additional information. Thank you! </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="showBlacklistWarning = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import config from '@/config'

import vueinterval from 'vue-interval/dist/VueInterval.common'
import {
  mapGetters
} from 'vuex'
import {
  INVITATIONS_STATUS_COLORS
} from '../libs/constants'

export default {
  data() {
    return {
      show: false,
      showBlacklistWarning: false,
      loading: false,
      tab: null,
      countPending: null,
      countActive: null,
      properties: [],
      statusColors: INVITATIONS_STATUS_COLORS
    }
  },
  mixins:[vueinterval],
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    }),
    filteredProperties() {
      if(this.tab === 1) {
        return this.properties.filter(item => item.status == 'Active').reverse()
      }
      return this.properties.filter(item => item.status != 'Active').reverse()
    }
  },
  created() {
    this.checkCurrentLogin()
    if(this.currentUser&&this.currentUser.isCompliance) {
      this.$router.push('/subscribers/');
    }
  },
  updated() {
    this.checkCurrentLogin()
  },
  methods: {
    checkCurrentLogin() {
      const now = Math.floor(Date.now() / 1000);
      if (!this.currentUser || !this.currentUser.isLoggedIn || !localStorage.token || now >= this.currentUser.expire) {
        window.location.href = `${config.loginUrl}?redirect_uri=${window.location.href}`;
      }
      this.currentUser.isCompliance = this.currentUser.auth.includes('ROLE_L_CM')
      this.currentUser.isManager = this.currentUser.auth.includes('ROLE_L_PM')
      this.currentUser.isEditor = this.currentUser.auth.includes('ROLE_PEND_PROP_ED')
    },
    INTERVAL__12e5$loadDataInvertal() {
      this.loadProperties()
    },
    loadProperties() {
      const _this = this
      this.loading = true;
      this.countPending = 0;
      this.countActive = 0;
      this.$http.get('/manager/properties/pending')
        .then(response => {
          _this.loading = false;
          const items = response.data || []
          items.sort(function(a, b) { return a.propertyId - b.propertyId })
          _this.properties = items
          _this.properties.forEach(function(v) {
            if (v['status'] == 'Active') {
              _this.countActive++
            } else {
              _this.countPending++
            }
            if (v['status'] == 'Alert Blacklist') {
              v['status'] = 'Coding'
            }
          })
        })
        .catch(() => {
          //this.loginFailed(e.error || 'An error occurred while processing your request.')
          _this.loading = false;
        })
    },
    clickProperty(property) {
      if (property.status == 'Coding') {
        this.$router.push('/coding/processing')
      } else if (property.status == 'Alert Blacklist') {
        //this.showBlacklistWarning = true
      }
      else {
        this.$router.push('/welcome/' + property.propertyId)
      }

    }
  },
  mounted() {
    this.show = true
    if(this.currentUser.isLoggedIn) {
      this.loadProperties()
    }
    this.checkCurrentLogin()
  }
}
</script>
<style>
.page-properties h4 {
  font-weight: 500;
  color: #2196F3;
}

.page-properties .v-list__tile__action {
  min-width: 120px;
}

.page-properties .v-chip {
  width: 100%;
}

.page-properties .v-chip__content {
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}

.page-properties .v-tabs__item {
  background: #efefef;
}

.page-properties .v-tabs__item--active {
  background: #fff;
}

.page-properties .v-tabs__slider-wrapper {
  top: 0;
  bottom: none;
}



</style>
